import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { BusViewer } from "./Pages/BusViewer/BusViewer";

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route index element={<BusViewer />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
