import olView from "ol/View";
import { WKT } from 'ol/format';
import { fromLonLat, toLonLat } from "ol/proj";
import { useEffect, useMemo, useState } from "react";
import { getByArea } from "../../Api/BusStopApi";
import BusViewerNavbar from "../../Components/BusViewerNavbar/BusViewerNavbar";
import { DrawInteraction } from "../../MapComponents/Interactions/DrawInteration";
import Interactions from "../../MapComponents/Interactions/Interactions";
import Layers from "../../MapComponents/Layers/Layers";
import TileLayer from "../../MapComponents/Layers/TileLayer";
import Map from "../../MapComponents/Map/Map";
import osm from "../../MapComponents/Source/osm";
import "./BusViewer.css";
import { Feature } from "ol";
import { Point, Polygon } from "ol/geom";
import MyVectorSource from "../../MapComponents/Source/VectorSource";
import Style from "ol/style/Style";
import Fill from "ol/style/Fill";
import Stroke from "ol/style/Stroke";
import CircleStyle from "ol/style/Circle.js";
import VectorLayer from "../../MapComponents/Layers/VectorLayer";
import { LoadingSpinner } from "../../Components/LoadingSpinner/LoadingSpinner";

export const BusViewer = () => {

  const [busStops, setBusStops] = useState([]);
  const [loading, setLoading] = useState(false);
  const [zoom, setZoom] = useState(12);
  const [extent, setExtent] = useState([0, 0, 0, 0]);

  const style = useMemo(() => {
    return new Style({
      image: new CircleStyle({
        fill: new Fill({ color: 'rgba(40, 24, 240, 0.4)' }),
        stroke: new Stroke({ color: 'rgba(40,24, 240,0.6)', width: 1 }),
        points: 4,
        radius: 6
      }),
    })
  });


  useEffect(() => {
    console.log("Bus Stops: ", busStops);
  }, [busStops]);


  const view = useMemo(
    () =>
      new olView({
        center: fromLonLat([-0.38466814, 51.404773]),
        zoom: zoom,
        projection: 'EPSG:3857',
      }),
    []
  );

  
  useEffect(() => {
    if(!view) return;
    view.on("change:resolution", () => {
      setZoom(view.getZoom());
    });

    view.on("change:center", () => {
      setExtent(view.calculateExtent());
    });
  },[view]);

  useEffect(() => {

    if(loading) return;
          if(zoom < 11) return;

          setBusStops([]);
    
          setLoading(true);
          // Get the area that represents the current view
          const extent = view.calculateExtent();
    console.log("extent", extent);
          // Change it to latlon
          const min = toLonLat([extent[0], extent[1]]);
          const max = toLonLat([extent[2], extent[3]]);
    
    console.log("min", min);
    console.log("max", max);
    
          // Turn it into a polygon
          const polygon = [
            [min[0], min[1]],
            [min[0], max[1]],
            [max[0], max[1]],
            [max[0], min[1]],
            [min[0], min[1]],
          ];
    
          // Create a WKT format instance
          const wktFormat = new WKT();
    
          // Get a wkt polygon
          const wktPolygon = wktFormat.writeGeometry(new Polygon([polygon]));
    
          getByArea(wktPolygon).then((response) => {
            console.log("Response: ", response.data);
            const busStopFeatures = response.data.map((busStop) => {
              console.log(busStop);
              var test = fromLonLat([busStop.location.coordinates[0], busStop.location.coordinates[1]]);
              console.log("Test: ", test);
              const busStopGeometry = new Point(test);
              console.log("Bus Stop Geometry: ", busStopGeometry);
              let feature = new Feature(busStopGeometry);
      
              return feature;
            });
      
            console.log(busStopFeatures);
      
            setBusStops(busStopFeatures);
            setLoading(false);
          });
  }, [zoom, extent]);

  const handleDrawEnd = (event) => {
    setLoading(true);
    const geometry = event.feature.getGeometry();
    const bngPolygon = geometry.clone().transform("EPSG:3857", "EPSG:4326");

    // Create a WKT format instance
    const wktFormat = new WKT();

    // Convert the polygon to WKT format
    const wktPolygon = wktFormat.writeGeometry(bngPolygon);

    getByArea(wktPolygon).then((response) => {
      console.log("Response: ", response.data);
      const busStopFeatures = response.data.map((busStop) => {
        console.log(busStop);
        var test = fromLonLat([busStop.location.coordinates[0], busStop.location.coordinates[1]]);
        console.log("Test: ", test);
        const busStopGeometry = new Point(test);
        console.log("Bus Stop Geometry: ", busStopGeometry);
        let feature = new Feature(busStopGeometry);

        return feature;
      });

      console.log(busStopFeatures);

      setBusStops(busStopFeatures);
      setLoading(false);
    });
  };

  return <>
    <BusViewerNavbar />
    {loading && <LoadingSpinner Message="Loading Bus Stops" />}
    <div className="sessionViewMapContainer">
      <Map view={view} >
        <Layers>
          <TileLayer source={osm()} zIndex={0} />
          <VectorLayer zIndex={1} style={style}>
            <MyVectorSource features={busStops} />
          </VectorLayer>
        </Layers>
        <Interactions>
          <DrawInteraction Enabled={!loading} OnDrawEnd={handleDrawEnd} />

        </Interactions>
      </Map>
    </div>
  </>;
};