import { Container, Nav, Navbar } from "react-bootstrap";
import { useNavigate } from "react-router-dom"; // Import this
import "./BusViewerNavbar.css";

function BusViewerNavbar() {
  const navigate = useNavigate(); // Get the navigate function

  return (
    <Navbar expand="lg" className="bg-body-tertiary navbar">
      <Container>
        <Navbar.Brand href="/"><img src="/icons8-point-stickers-32.png" />Bus Viewer</Navbar.Brand>
        {/* <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="Municipalities" style={{color:'white'}}>Municipalities</Nav.Link>
            <Nav.Link href="Sessions" style={{color:'white'}}>Manage Sessions</Nav.Link>
            <Nav.Link style={{color:'white'}} onClick={handleLogout}>Logout</Nav.Link>
          </Nav>
        </Navbar.Collapse> */}
      </Container>
    </Navbar>
  );
}

export default BusViewerNavbar;
